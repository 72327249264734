import React from 'react'
import { graphql, Link } from 'gatsby'
import loadable from '@loadable/component'
import { FaArrowLeft } from 'react-icons/fa'
import scrollTo from 'gatsby-plugin-smoothscroll'

import { themes } from '../global'
import Seo from 'components/Seo'
import PageLayout from '../layouts/PageLayout'
import Section from 'components/Section/Section'
import { Button } from 'components/Button'
import { getImage } from 'gatsby-plugin-image'
import { Grid, Cell } from 'components/Grid'
import { cellStrain } from 'components/Grid/Cell.module.scss'

const Breadcrumb = loadable(() => import('../components/Breadcrumb'))

const StrainTemplate = ({
  data: {
    markdownRemark: {
      frontmatter: { name, isSoldOut, description, descriptionHtml, photo },
    },
  },
  location: { pathname },
}) => {
  const backgroundImage = getImage(photo)
  const ctaButton = {
    buttonText: 'Contact Us',
    onClick: () => scrollTo('#contact'),
  }
  return (
    <PageLayout pathname={pathname}>
      <Seo
        title={`${name} | Strains | Iliad Epic Grow`}
        description={description}
        pathname={pathname}
      />
      <Section
        as='header'
        id={`page-section-0`}
        fullHeight
        backgroundImage={backgroundImage}
        colorOverlay
        colorTheme='black'
        ctaButton={ctaButton}>
        <Grid
          className={`iliad__section--content-container iliad__section--content-container-posY-middle`}>
          <Cell
            hStart={2}
            hSpan={7}
            hSpanL={8}
            hSpanM={8}
            hSpanS={12}
            className={`iliad__section--content ${cellStrain}`}>
            <Breadcrumb
              parentName='Products'
              parentURL='/products/'
              pathname={pathname}
              title={name}
            />
            <h1>{name}</h1>
            {isSoldOut && <p className='iliad__section--sold-out'>Sold Out</p>}

            <div
              className='iliad__strain-template--description'
              dangerouslySetInnerHTML={{ __html: descriptionHtml }}
            />

            <p>
              <Link className='iliad__strain--back-to-products' to='/products/'>
                <FaArrowLeft /> Back to Products
              </Link>
            </p>
            <Button
              buttonText='Order Now'
              buttonAction='scrollContact'
              colorTheme={themes['black']}
            />
          </Cell>
        </Grid>
      </Section>
    </PageLayout>
  )
}

export default StrainTemplate

export const StrainTemplateQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        name
        description
        descriptionHtml
        isSoldOut
        photo {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
